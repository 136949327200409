import gql from 'graphql-tag'

export default gql`
    query insights($UserID: String, $All: Boolean) {
        insights(UserID: $UserID, All: $All) {
            GeneratedAt
            InsightID
            UserID
            Current
            Domain
            Coins
            Status
            TTL
            LogGroup
            LogStream
            ExecID
            Error
            BillId
        }
    }
`
