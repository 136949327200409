import * as React from 'react'
import * as gql from 'graphql/types'
import { useQuery } from '@apollo/client'
import * as styles from './styles'
import userAudit from 'graphql/queries/userAudit'
import userEvent from 'graphql/queries/userEvent'
type Props = {
    user: gql.User
}

const UserBehvaior = ({ user }: Props) => {
    const [show, setShow] = React.useState<Array<string>>([])
    const { loading, error, data, refetch } = useQuery(userEvent, {
        variables: { UserID: user.user_id },
    })
    if (loading) return <></>
    if (error) return <div>ISSUE LOADING AUDIT INFO - {error.message}</div>

    if (!data.userEvent) return <></>
    let list = [...data.userEvent] as gql.UserEvent[]
    console.log(show)
    list.sort((a, b) => {
        return Number(a.CreatedAt) - Number(b.CreatedAt)
    })
    return (
        <div className={styles.container}>
            <div>
                {list.map((a: gql.UserEvent) => {
                    const date = new Date(Number(a.CreatedAt))
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <div>
                            {date.toLocaleString()} - {a.Type}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default UserBehvaior
