import b from 'bss'
import * as CommonStyles from 'components/common/CommonStyles'

export const headerContainer = b`
    display: grid;
    grid-template-columns: 19fr 2fr 1fr;
    margin-bottom: 5px; 
`.class

export const email = b`
    font-size: 30px; 
    font-weight: bold
`.class

export const source = b`
    font-size: 26px; 
    font-weight: bold
`.class

export const group = b`
    text-transform: uppercase;
    font-size: 30px; 
    background: ${CommonStyles.BlueText};
    color: #fff;
    border-radius: 8px; 
    padding: 10px 20px;
    cursor:pointer;
`.class

export const button = b`
    text-transform: uppercase;
    background: ${CommonStyles.Green};
    border-radius: 2px; 
    font-weight: bold; 
    width: 100px;
    line-height: 30x;
    text-align:center;
    display: inline-block;  
`.class
export const userGroup = b`
    text-transform: uppercase;
    font-size: 30px; 
    background: ${CommonStyles.Green};
    color: #fff;
    border-radius: 8px; 
    padding: 10px 20px;

`.class

export const container = b`
    border: 1px solid rgba(0, 0, 0, 0.8);
    background: #fff;
    padding: 5px;
    overflow:auto;
`.class

export const accountRow = b`
    display: grid;
    grid-template-columns: 5fr 1fr 1fr 1fr 1fr 1fr;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin-top: 12px;
    padding-bottom: 12px;
`.class

export const rowLayout = b`
    display: grid;
    grid-template-columns: 2fr 1fr 2fr 1fr 1fr;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin-top: 12px;
    padding-bottom: 12px;
`.class

export const primary = b`
    font-family: ${CommonStyles.BaseFont};
    color: ${CommonStyles.White100};
    font-size: ${CommonStyles.SmallSize}
    font-weight: ${CommonStyles.MediumWeight}
    margin: 0px 0px 4px 0px;
    grid-area: primary;
`.class

export const secondary = b`
    font-family: ${CommonStyles.BaseFont};
    color: ${CommonStyles.White50};
    font-size: ${CommonStyles.SmallSize}
    font-weight: ${CommonStyles.NormalWeight}
    margin: 0px 0px 0px 0px;
    grid-area: secondary;
`.class

export const tertiary = b`
    font-family: ${CommonStyles.NumberFont};
    color: ${CommonStyles.White100};
    font-size: ${CommonStyles.MediumSize}
    font-weight: ${CommonStyles.NormalWeight}
    text-align: right;
    align-self: center;
    margin: 0px;
`.class

export const icon = b`
    grid-area: icon;
    justify-self: center;
`.class

export const iconGrid = b`
    display: grid;
    grid-template-columns: 20px auto;
    grid-template-areas: 
        "icon primary"
        "icon secondary"
    column-gap: 20px;
`.class

export const smallerText = b`
    font-size: ${CommonStyles.ExtraSmallSize} !important;
`.class

export const positiveNumber = b`
    color: ${CommonStyles.Green}
`.class

export const attribute = b`
    float:left;
    margin-right: 5px; 
    border: 1px solid #222;
    background: #fff
    border-radius: 3px;
    margin-bottom:3px; 
    overflow:hidden;
`.class

export const attributeLabel = b`
    background: ${CommonStyles.BlueText};
    padding: 10px 5px; 
    color: white;
    float:left;
    border-right: 1px solid black;
    margin-right: 5px;
`.class

export const attributeValue = b`
    font-size: ${CommonStyles.ExtraSmallSize} !important;
    float:left;
    height: 100%;
    padding: 10px 5px; 
    margin-top:3px
`.class

export const attributesContainer = b`
    padding:10px 0;
    clear: both; 
    border-top: 1px solid rgba(0, 0, 0, 0.8);
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);
    overflow:auto;
    margin-bottom:10px;
`.class

export const accordianStyles = {
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}

export const AccordionSummaryStyles = {
    root: {
        backgroundColor: '#fff',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
}

export const AccordionDetailStyles = {
    root: {
        backgroundColor: '#f4f6f8',
        padding: '5px',
        display: 'block',
        overflow: 'auto',
    },
}

export const groupContainer = b`
    border: 1px solid #222;
    background: #eee;
    padding: 3px 5px; 
    margin: 8px 10px;
`.class

export const groupItem = b`
   background: #fff;
   margin-bottom: 2px;
   cursor:pointer;
`.class
