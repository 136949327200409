import { Logo } from 'components/common/Logo'
import React from 'react'
import * as BiIcons from 'react-icons/bi'
import { Link } from 'react-router-dom'

import { Box, BoxProps, Center, Flex, Stack, useColorModeValue as mode } from '@chakra-ui/react'

import { AccountSwitcher } from '../common/AccountSwitcher'
import { NavGroup } from '../common/NavGroup'
import { NavItem } from '../common/NavItem'
import { AuthContext } from 'App'
import StaffSidebar from './StaffSidebar'

export default function Sidebar({ children }: BoxProps) {
    const auth = React.useContext(AuthContext)

    if (auth.token!['http://acacia.com/staff']) return <StaffSidebar>{children}</StaffSidebar>
    return (
        <Box height="100vh" overflow="hidden" position="relative" zIndex="1">
            <Flex h="full" id="app-container">
                <Box w="64" bg="gray.900" color="white" fontSize="sm">
                    <Flex h="full" direction="column" px="4" py="4">
                        <Link to="/">
                            <Center>
                                <Logo h="8" mb="4" mt="4" />
                            </Center>
                        </Link>
                        <Stack spacing="8" flex="1" overflow="auto" pt="4">
                            <Stack spacing="1">
                                <NavItem to="/" icon={<BiIcons.BiArch />} label="Dashboard" />
                            </Stack>
                            <NavGroup label="Metrics &amp; Analysis">
                                <NavItem
                                    to="/analysis/users"
                                    icon={<BiIcons.BiUser />}
                                    label="Acacia Users"
                                />
                                <NavItem
                                    to="/analysis/energy"
                                    icon={<BiIcons.BiAbacus />}
                                    label="Energy Engagement"
                                />
                                <NavItem
                                    to="/analysis/channel"
                                    icon={<BiIcons.BiAbacus />}
                                    label="Channel Analysis"
                                />
                                <NavItem
                                    to="/analysis/savings"
                                    icon={<BiIcons.BiBuildingHouse />}
                                    label="Bank Switching"
                                />
                                <NavItem
                                    to="/analysis/carbon"
                                    icon={<BiIcons.BiBuildingHouse />}
                                    label="Carbon Analysis"
                                />
                                <NavItem
                                    to="/analysis/super"
                                    icon={<BiIcons.BiLineChart />}
                                    label="Super Engagement"
                                />
                            </NavGroup>
                            <NavGroup label="Users &amp; Features">
                                <NavItem
                                    to="/users"
                                    icon={<BiIcons.BiUserCircle />}
                                    label="All Users"
                                />
                                <NavItem
                                    to="/userGroups"
                                    icon={<BiIcons.BiUserCircle />}
                                    label="Groups"
                                />
                                <NavItem
                                    to="/partners"
                                    icon={<BiIcons.BiUserCircle />}
                                    label="Partners"
                                />
                                <NavItem
                                    to="/features"
                                    icon={<BiIcons.BiCategoryAlt />}
                                    label="Feature Flags"
                                />
                                <NavItem
                                    to="/whitelabels"
                                    icon={<BiIcons.BiWindow />}
                                    label="Whitelabel UIs"
                                />
                                {process.env.ENVIRONMENT === 'prod' ||
                                window.location.href.indexOf('prod') > -1 ? null : (
                                    <NavItem
                                        to="/releases"
                                        icon={<BiIcons.BiBulb />}
                                        label="Releases"
                                    />
                                )}
                                {/* <NavItem to="/events" icon={<BiIcons.BiAlbum />} label="Events" /> */}
                                <NavItem
                                    to="/push"
                                    icon={<BiIcons.BiAlbum />}
                                    label="Push Notifications"
                                />
                                <NavItem
                                    to="/campaigns"
                                    icon={<BiIcons.BiAlbum />}
                                    label="Campaigns"
                                />
                                <NavItem
                                    to="/switches"
                                    icon={<BiIcons.BiAlbum />}
                                    label="Switches"
                                />
                            </NavGroup>
                            <NavGroup label="CDR / Product Data">
                                <NavItem
                                    to="/cdr/pending"
                                    icon={<BiIcons.BiWater />}
                                    label="Pending Maps"
                                />
                                <NavItem
                                    to="/cdr/maps"
                                    icon={<BiIcons.BiDialpadAlt />}
                                    label="Current Maps"
                                />
                                <NavItem
                                    to="/cdr/accountTypes"
                                    icon={<BiIcons.BiBaseball />}
                                    label="Account Types"
                                />
                                <NavItem
                                    to="/cdr/institutions"
                                    icon={<BiIcons.BiLayer />}
                                    label="Institution Status"
                                />
                                <NavItem
                                    to="/cdr/new"
                                    icon={<BiIcons.BiNews />}
                                    label="New Products"
                                />
                                <NavItem
                                    to="/cdr/updates"
                                    icon={<BiIcons.BiBulb />}
                                    label="Acacia Product Updates"
                                />
                            </NavGroup>
                            <NavGroup label="Client Mapping">
                                <NavItem
                                    to="/cdr/ignored"
                                    icon={<BiIcons.BiToggleLeft />}
                                    label="Ignored Accounts"
                                />
                                <NavItem
                                    to="/cdr/failed"
                                    icon={<BiIcons.BiToggleLeft />}
                                    label="Failed Accounts"
                                />
                                <NavItem
                                    to="/cdr/failingInst"
                                    icon={<BiIcons.BiToggleLeft />}
                                    label="Failing Institutions"
                                />
                            </NavGroup>
                            <NavGroup label="Prototypes">
                                <NavItem
                                    to="/widgets/carbon"
                                    icon={<BiIcons.BiToggleLeft />}
                                    label="Carbon widget"
                                />
                                <NavItem
                                    to="/widgets/energy"
                                    icon={<BiIcons.BiToggleLeft />}
                                    label="Energy widget"
                                />
                            </NavGroup>
                        </Stack>
                        <Box>
                            <Stack spacing="1">
                                <AccountSwitcher />
                            </Stack>
                        </Box>
                    </Flex>
                </Box>
                <Box bg={mode('white', 'white')} flex="1" p="6" overflow="scroll">
                    {children}
                </Box>
            </Flex>
        </Box>
    )
}
