import { useMutation, useQuery } from '@apollo/client'
import { Button, MenuItem, Select, TextField, Dialog, DialogContent } from '@material-ui/core'

import * as React from 'react'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../common/CommonStyles'
import users from 'graphql/queries/users'
import getCampaigns from 'graphql/queries/getCampaigns'
import { StylesProvider } from '@chakra-ui/react'
import * as styles from './styles'
import { stringify } from 'querystring'
import MaterialTable, { Icons } from 'material-table'
import { tableIcons } from 'components/common/MaterialIcons'
import CampaignDetails from './deets'
import { Campaign, CampaignEvent } from 'graphql/types'

const Campaigns = () => {
    const { loading, error, data, refetch } = useQuery(getCampaigns, { fetchPolicy: 'no-cache' })
    if (loading) return <div>Loading</div>
    if (error) return <div>failure {console.error(error)} </div>
    if (!data.getCampaigns) data.getCampaigns = []
    const refetchCampaigns = () => refetch()

    const thisData = data.getCampaigns.map((a: Campaign) => {
        return {
            ID: a.ID,
            DateCreated: a.DateCreated,
            DateCompleted: a.DateCompleted,
            DateDrafted: a.DateDrafted,
            TotalPushes: a.TotalPushes,
            Message: a.Message,
            Title: a.Title,
            Description: a.Description,
            Status: a.Status,
            Logic: a.Logic
        }
    })
    return (
        <div>
            <div>
                <BiIcons.BiWindow size={35} style={{ display: 'inline-block' }} />
                <h1
                    style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                    className={CommonStyles.MainHeaderClass}
                >
                    Campaigns
                </h1>
            </div>
            <div>
                <MaterialTable
                    icons={tableIcons as Icons}
                    title={''}
                    columns={[
                        { title: 'ID', field: 'ID'},
                        { title: 'Description', field: 'Description' },
                        { title: 'DateCreated', field: 'DateCreated' },
                        { title: 'Status', field: 'Status' },
                        { title: 'Logic', field: 'Logic' },
                    ]}
                    data={thisData}
                    options={{
                        showTitle: false,
                        sorting: true,
                        filtering: true,
                        search: true,
                        pageSize: 50,
                    }}
                    // onRowClick={(event, rowData: any) => {
                    //     window.open(`/users/${rowData.UserID}`, '_blank')
                    // }}
                    detailPanel={(rowData) => {
                        return <CampaignDetails Campaign={rowData as Campaign} refetchCampaigns={refetchCampaigns}/>
                    }}
                />
            </div>
        </div>
    )
}

export default Campaigns
